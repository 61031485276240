<template>
    <section class="lg:py-20 lg:px-20 p-3">
        <div class="text-center text-3xl mb-10">
            Kalkulasi Asuransi
        </div>

        <div class="flex justify-center space-x-5">
            <button @click="selectBtn('car')" class="bg-gray-200  py-2 px-8 uppercase tracking-widest focus:outline-none" :class="{'bg-brownA': isCar, 'text-white': isCar}">Mobil</button>
            <button @click="selectBtn('motor')" class="bg-gray-200 py-2 px-8 uppercase tracking-widest focus:outline-none" :class="{'bg-brownA': !isCar, 'text-white': !isCar}">Motor</button>
        </div>

        <div v-if="quotCar" >
            <div v-if="quotCar && isCar" class="wrapper-car bg-gray-200" >
                <form action="" @submit.prevent="" ref="carForm">
                    <div class="car">
                        <label for="">Period</label>
                        <select name="" id="" v-model="car.COVERAGE_YEAR_UNTIL">
                            <option value=1>1 Year</option>
                            <option value=2>2 Years</option>
                            <option value=3>3 Years</option>
                            <option value=4>4 Years</option>
                            <option value=5>5 Years</option>
                        </select>
                    </div>
                    <div class="car">
                        <label for="">Vechile Type</label>
                        <select name="" id="" v-model="car.VEHICLE_CATEGORY_ID">
                            <option :value="value.VEHICLE_CATEGORY_ID" v-for="(value, idx) in quotCar.VEHICLE_CATEGORY" :key="idx">{{value.VEHICLE_CATEGORY_NAME}}</option>
                        </select>
                    </div>
                    <div class="car">
                        <label for="">Vechile Year</label>
                        <select name="" id="" v-model="car.VEHICLE_YEAR">
                            <option :value="value.VEHICLE_YEAR" v-for="(value, idx) in quotCar.VEHICLE_YEAR" :key="idx">{{value.VEHICLE_YEAR}}</option>
                        </select>
                    </div>
                    <div class="car">
                        <label for="">Used For</label>
                        <select name="" id="" v-model="car.USED_FOR">
                            <option value="Private">Private</option>
                            <option value="Commercial">Commercial</option>
                        </select>
                    </div>
                    <div class="car" v-if=" car.USED_FOR === 'Commercial'">
                        <label for="">Commercial Rate (%)</label>
                        <input placeholder="Rate (%)" required type="number" inputmode="numeric" step="0.01" min="0" max="100" v-model="car.CAR_COMMERCIAL_USED_EXTRA_RATE">
                    </div>
                    <div class="car">
                        <label for="">Vechile Price (Rp)</label>
                        <div class="space-x-1">
                            <span>Rp</span>
                            <input  required type="number" inputmode="numeric" v-model="car.VEHICLE_PRICE"/>
                        </div>
                    </div>

                    <div  v-for="(n, index) in car.COVERAGE_YEAR_UNTIL - 1" :key="index">
                        <div v-if="n > 0" class="car">
                            <label for="">{{yearTitle(n + 1)}}</label>
                            <div class="space-x-5 flex">
                                <div class="space-x-1">
                                    <span>(%)</span>
                                    <input required type="number"  inputmode="numeric" step="0.01" min="0" max="100" v-model="preCar.VEHICLE_PRICE_PERCENTAGE_ARR[n]"/>
                                </div>
                                <div class="space-x-1">
                                    <span>Rp</span>
                                    <input  required type="number" inputmode="numeric" v-model="preCar.VEHICLE_PRICE_AMOUNT_ARR[n]"/>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="car">
                        <label for="">Region</label>
                        <select name="" id="" v-model="car.REGION_ID">
                            <option :value="value.REGION_ID" v-for="(value, idx) in quotCar.REGION_DATA" :key="idx">{{value.REGION_DESC}}</option>
                        </select>
                    </div>       
                    <div class="car">
                        <label for="">Coverage</label>
                        <select name="" id="" v-model="car.COVERAGE_ID">
                            <option :value="value.COVERAGE_ID" v-for="(value, idx) in quotCar.COVERAGE_DATA" :key="idx">{{value.COVERAGE_NAME}}</option>
                        </select>
                    </div>        
                    <div class="car">
                        <label for="">RSCC - Commotion</label>
                        <select name="" id="" v-model="car.IS_RSCC">
                            <option value="1">YES</option>
                            <option value="0">NO</option>
                        </select>
                    </div>   
                    <div class="car">
                        <label for="">TS - Terrorism</label>
                        <select name="" id="" v-model="car.IS_TS">
                            <option value="1">YES</option>
                            <option value="0">NO</option>
                        </select>
                    </div>   
                    <div class="car">
                        <label for="">FWTWD - Flood</label>
                        <select name="" id="" v-model="car.IS_FWTWD">
                            <option value="1">YES</option>
                            <option value="0">NO</option>
                        </select>
                    </div>   
                    <div class="car">
                        <label for="">EVET - Earthquake</label>
                        <select name="" id="" v-model="car.IS_EVET">
                            <option value="1">YES</option>
                            <option value="0">NO</option>
                        </select>
                    </div>     
                    
                    <div class="car">
                        <label for="">Workshop</label>
                        <select name="" id="" v-model="car.IS_AUTHORIZED_WORKSHOP">
                            <option value="0">General Workshop</option>
                            <option value="1">Authorized Workshop</option>
                        </select>
                    </div>   
                    <div class="car" v-if="car.IS_AUTHORIZED_WORKSHOP === '1'">
                        <label for="">Official Workshop Rate (%)</label>
                        <input placeholder="Rate (%)" required type="number" inputmode="numeric" step="0.01" min="0" max="100" v-model="car.OTHER_RATE">
                    </div>  
                    <!-- <div class="car">
                        <label for="">Discount (MAX 25%)</label>
                        <div class="space-x-1">
                            <span>(%)</span>
                            <input  type="number" inputmode="numeric" step="0.01" min="0" max="25" v-model="car.DISCOUNT_PERCENTAGE"/>
                        </div>
                    </div>   -->
                    <div class="car">
                        <label for="">Third party Liability (TPL)</label>
                        <select name="" id="" v-model="car.IS_THIRDPARTY">
                            <option value="1">YES</option>
                            <option value="0">NO</option>
                        </select>
                    </div>        
                    <div class="car">
                        <label for="">TPL LIMIT</label>
                        <select name="" id="" v-model="car.THIRDPARTY_LIMIT">
                            <option :value="value.THIRD_PARTIES_COVER_AMOUNT" v-for="(value, idx) in quotCar.THIRDPARTY_AMOUNT" :key="idx">{{value.THIRD_PARTIES_COVER_AMOUNT_TXT}}</option>
                        </select>
                    </div>      
                    <div class="car">
                        <label for="">PA Driver</label>
                        <select name="" id="" v-model="car.IS_PADRIVER">
                            <option value="1">YES</option>
                            <option value="0">NO</option>
                        </select>
                    </div>        
                    <div class="car">
                        <label for="">PAD LIMIT</label>
                        <select name="" id="" v-model="car.PADRIVER_LIMIT">
                            <option :value="value.DRIVER_PERSONAL_ACCIDENT_COVER_AMOUNT" v-for="(value, idx) in quotCar.DRIVER_AMOUNT" :key="idx">{{value.DRIVER_PERSONAL_ACCIDENT_COVER_AMOUNT_TXT}}</option>
                        </select>
                    </div>       
                    <div class="car">
                        <label for="">PA Passenger</label>
                        <select name="" id="" v-model="car.IS_PAPASSENGER">
                            <option value="1">YES</option>
                            <option value="0">NO</option>
                        </select>
                    </div>        
                    <div class="car">
                        <label for="">PAP LIMIT</label>
                        <select name="" id="" v-model="car.PAPASSENGER_LIMIT">
                            <option :value="value.PASSENGER_PERSONAL_ACCIDENT_COVER_AMOUNT" v-for="(value, idx) in quotCar.PASSENGER_AMOUNT" :key="idx">{{value.PASSENGER_PERSONAL_ACCIDENT_COVER_AMOUNT_TXT}}</option>
                        </select>
                    </div>     
                    <div class="car">
                        <label for="">No of Passenger</label>
                        <select name="" id="" v-model="car.PAPASSENGER_TOTAL">
                            <option :value="value.PASSENGER_LIMIT" v-for="(value, idx) in quotCar.PASSENGER_LIMIT" :key="idx">{{value.PASSENGER_LIMIT}}</option>
                        </select>
                    </div> 
                    <button @click="postCalculate" type="submit" class="bg-brownA text-white py-3 px-8 mt-5">Mulai Kalkulasi</button> 
                </form>  
            </div>

            <div class="space-y-10 mt-10 mb-20" v-if="resultCar && isCar">
                <div class="text-center text-2xl mb-10">
                    Hasil Kalkulasi Mobil
                </div>
                <div v-for="(value, idx) in resultCar.QUOT_LIST" :key="idx" class="bg-gray-100 p-5 space-y-2">
                    <div class="text-right text-2xl font-bold">{{idx + 1}}</div>
                    <div class="grid grid-cols-2">
                        <span>Premi Basic Amount</span>
                        <span>{{value.PREMIUM_BASIC_AMOUNT}}</span>
                    </div>
                    <div  class="grid grid-cols-2">
                        <span>Pihat Ke-3</span>
                        <span>{{value.THIRD_PARTIES_PREMIUM_AMOUNT}}</span>
                    </div>
                    <div class="grid grid-cols-2">
                        <span>PA Driver</span>
                        <span>{{value.DRIVER_PERSONAL_ACCIDENT_AMOUNT}}</span>
                    </div>
                    <div class="grid grid-cols-2">
                        <span>PA Passenger</span>
                        <span>{{value.PASSENGER_PERSONAL_ACCIDENT_AMOUNT}}</span>
                    </div>
                    <div class="grid grid-cols-2">
                        <span>Discount</span>
                        <span class="text-red-700">{{value.DISCOUNT_AMOUNT}}</span>
                    </div>
                    <div class="grid grid-cols-2">
                        <span>Sub Total Premi </span>
                        <span>{{value.TOTAL_PREMIUM_AMOUNT}}</span>
                    </div>
                </div>
                <div class="text-right text-lg">Total Premi {{resultCar.GRAND_TOTAL}}</div>
            </div>


            
            
            <!-- MOTOR -->
            <div v-if="quotCar && !isCar" class="wrapper-car bg-gray-200 mt-40">
                <form action="" @submit.prevent="" ref="motorForm">
                    <div class="car">
                        <label for="">Period</label>
                        <select name="" id="" v-model="motor.COVERAGE_YEAR_UNTIL">
                            <option value=1>1 Year</option>
                            <option value=2>2 Years</option>
                            <option value=3>3 Years</option>
                            <option value=4>4 Years</option>
                            <option value=5>5 Years</option>
                        </select>
                    </div>
                    <div class="car">
                        <label for="">Vechile Year</label>
                        <select name="" id="" v-model="motor.VEHICLE_YEAR">
                            <option :value="value.VEHICLE_YEAR" v-for="(value, idx) in quotCar.VEHICLE_YEAR" :key="idx">{{value.VEHICLE_YEAR}}</option>
                        </select>
                    </div>
                    <div class="car">
                        <label for="">Used For</label>
                        <select name="" id="" v-model="motor.USED_FOR">
                            <option value="Private">Private</option>
                            <option value="Commercial">Commercial</option>
                        </select>
                    </div>
                    <div class="car">
                        <label for="">Total Rate</label>
                        <select name="" id="" v-model="motor.GRADE_RATE">
                            <option :value="el.LIMO_GRADE_CODE" v-for="(el, idx) in gradeCode"  :key="idx">{{ el.LIMO_GRADE_NAME }}</option>
                        </select>
                    </div>
                    <div class="car">
                        <label for="">Vechile Price (Rp)</label>
                        <div class="space-x-1">
                            <span>Rp</span>
                            <input  required type="number" inputmode="numeric" v-model="motor.VEHICLE_PRICE"/>
                        </div>
                    </div>

                    <div  v-for="(n, index) in motor.COVERAGE_YEAR_UNTIL - 1" :key="index">
                        <div v-if="n > 0" class="car">
                            <label for="">{{yearTitle(n + 1)}}</label>
                            <div class="space-x-5 flex">
                                <div class="space-x-1">
                                    <span>(%)</span>
                                    <input required type="number"  inputmode="numeric" step="0.01" min="0" max="100" v-model="preMotor.VEHICLE_PRICE_PERCENTAGE_ARR[n]"/>
                                </div>
                                <div class="space-x-1">
                                    <span>Rp</span>
                                    <input  required type="number" inputmode="numeric" v-model="preMotor.VEHICLE_PRICE_AMOUNT_ARR[n]"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="car">
                        <label for="">Discount (MAX 25%)</label>
                        <div class="space-x-1">
                            <span>(%)</span>
                            <input  type="number" inputmode="numeric" step="0.01" min="0" max="25" v-model="motor.DISCOUNT_PERCENTAGE"/>
                        </div>
                    </div>   -->
                    <button @click="postCalculateMotor" type="submit" class="bg-brownA text-white py-3 px-8 mt-5">Mulai Kalkulasi</button> 
                </form>  
            </div>

            <div class="space-y-10 mt-10 mb-20" v-if="resultMotor && !isCar">
                <div class="text-center text-2xl mb-10">
                    Hasil Kalkulasi Motor
                </div>
                <div v-for="(value, idx) in resultMotor.QUOT_LIST" :key="idx" class="bg-gray-100 p-5 space-y-2">
                    <div class="text-right text-2xl font-bold">{{idx + 1}}</div>
                    <div class="grid grid-cols-2">
                        <span>Premi Basic Amount</span>
                        <span>{{value.PREMIUM_BASIC_AMOUNT}}</span>
                    </div>
                    <!-- <div  class="grid grid-cols-2">
                        <span>Pihat Ke-3</span>
                        <span>{{value.THIRD_PARTIES_PREMIUM_AMOUNT}}</span>
                    </div>
                    <div class="grid grid-cols-2">
                        <span>PA Driver</span>
                        <span>{{value.DRIVER_PERSONAL_ACCIDENT_AMOUNT}}</span>
                    </div>
                    <div class="grid grid-cols-2">
                        <span>PA Passenger</span>
                        <span>{{value.PASSENGER_PERSONAL_ACCIDENT_AMOUNT}}</span>
                    </div> -->
                    <div class="grid grid-cols-2">
                        <span>Discount</span>
                        <span class="text-red-700">{{value.DISCOUNT_AMOUNT}}</span>
                    </div>
                    <div class="grid grid-cols-2">
                        <span>Sub Total Premi </span>
                        <span>{{value.TOTAL_PREMIUM_AMOUNT}}</span>
                    </div>
                </div>
                <div class="text-right text-lg">Total Premi {{resultMotor.GRAND_TOTAL}}</div>
            </div>
        </div>

        <div v-else class="flex justify-center">
            <img src="@/assets/kalkulasi/spinner.gif" alt="">
        </div>

        <div class="mt-40"></div>
    </section>
</template>

<script>
import axios from 'axios'
// const url = "http://157.245.203.195/TROYA_API/";
// const url = "https://forward-troya.vercel.app/troya"
const url = "https://api.troyaindo.co.id/api/v1/app"
const axs = axios.create({
    baseURL: url
})

export default {
    data() {
        return {
            quotCar: null,
            preCar: {
                VEHICLE_PRICE_PERCENTAGE_ARR: [],
                VEHICLE_PRICE_AMOUNT_ARR: []
            },
            ISSET_PERCENT: true,
            ISSET_PRICE: true,
            car: {
                COVERAGE_YEAR_UNTIL: "1",
                VEHICLE_CATEGORY_ID: "1",
                VEHICLE_YEAR: "2023",
                USED_FOR: "Private",
                REGION_ID: "2",
                COVERAGE_ID: "1",
                IS_RSCC: "1",
                IS_TS: "1",
                IS_FWTWD: "1",
                IS_EVET: "1",
                IS_AUTHORIZED_WORKSHOP: "0",
                IS_THIRDPARTY: "1",
                THIRDPARTY_LIMIT: "10000000",
                IS_PADRIVER: "1",
                PADRIVER_LIMIT:"5000000",
                IS_PAPASSENGER: "1",
                PAPASSENGER_LIMIT: "5000000",
                PAPASSENGER_TOTAL: "4"
            },
            isCar: true,
            resultCar: null,
            preMotor: {
                VEHICLE_PRICE_PERCENTAGE_ARR: [],
                VEHICLE_PRICE_AMOUNT_ARR: []
            },
            M_ISSET_PERCENT: true,
            M_ISSET_PRICE: true,
            motor: {
                COVERAGE_YEAR_UNTIL: "1",
                VEHICLE_YEAR: "2023",
                USED_FOR: "Private",
                GRADE_RATE: ''
            },
            resultMotor: null,
            gradeCode: null
        }
    },
    methods: {
        getData: async function() {
            try {
            const {data} = await axs.post('Quotation/QuotDropdownData', new URLSearchParams({
                USER_TOKEN: 'U2FsdGVkX1+QQRAUA4cDHV3CD7OiRO1vKeeFITPDcSOglhh4FySAFzoI9in1iwvyOYHAC436mVyznYVTJRPajg=='
                }))   
                this.quotCar = data
            } catch (error) {
                console.log(error)
            }
            try {
                const { data } = await axs.get('Quotation/selectMotorcycleLimoRateList')
                this.gradeCode = data.data
                this.motor.GRADE_RATE = data.data[0].LIMO_GRADE_CODE
                this.motor.TOTAL_RATE = data.data[0].LIMO_GRADE_RATE
            } catch (error) {
                console.log(error)
            }
        },
        postCalculate: async function() {
            if (!this.$refs.carForm.checkValidity()) {
                return this.$refs.carForm.reportValidity();
            }
            this.resultCar = null;

            try {
                const send = JSON.parse(JSON.stringify(this.car))
                send.VEHICLE_PRICE = this.changeLetters(send.VEHICLE_PRICE, ',','.')
                
                send.OTHER_RATE = this.changeLetters(send.OTHER_RATE, ',','.')
                send.USER_TOKEN = 'U2FsdGVkX1+QQRAUA4cDHV3CD7OiRO1vKeeFITPDcSOglhh4FySAFzoI9in1iwvyOYHAC436mVyznYVTJRPajg=='

                if (send.IS_THIRDPARTY == '0') delete send.THIRDPARTY_LIMIT;
                if (send.IS_PADRIVER == '0') delete send.PADRIVER_LIMIT;
                if (send.IS_PAPASSENGER == '0') delete send.PAPASSENGER_LIMIT;
                if (send.IS_AUTHORIZED_WORKSHOP == '0') delete send.OTHER_RATE;

                if(send.DISCOUNT_PERCENTAGE ) {
                    send.DISCOUNT_PERCENTAGE = this.changeLetters(send.DISCOUNT_PERCENTAGE, ',','.')
                } else {
                    delete send.DISCOUNT_PERCENTAGE
                }


                if(send.OTHER_RATE ) {
                    send.OTHER_RATE = this.changeLetters(send.OTHER_RATE, ',','.')
                } else {
                    delete send.OTHER_RATE
                }

                if (send.USED_FOR === 'Commercial') {
                    send.CAR_COMMERCIAL_USED_EXTRA_RATE = this.changeLetters(send.CAR_COMMERCIAL_USED_EXTRA_RATE, `,`, `.`);
                } else {
                    delete send.CAR_COMMERCIAL_USED_EXTRA_RATE;
                }

               

                const { data } = await axs.post('Quotation/QuotCalculateRate', new URLSearchParams(send))

                const result = await axs.post('Quotation/QuotGetData', new URLSearchParams({
                    USER_TOKEN: 'U2FsdGVkX1+QQRAUA4cDHV3CD7OiRO1vKeeFITPDcSOglhh4FySAFzoI9in1iwvyOYHAC436mVyznYVTJRPajg==',
                    QUOT_ID: data.QUOT_ID
                }))

                this.resultCar = result.data
            } catch (error) {
                console.log(error)
            }

        },
        postCalculateMotor: async function() {
            if (!this.$refs.motorForm.checkValidity()) {
                return this.$refs.motorForm.reportValidity();
            }
            this.resultMotor = null;

            try {
                const send = JSON.parse(JSON.stringify(this.motor))
                // send.DISCOUNT_PERCENTAGE = this.changeLetters(send.DISCOUNT_PERCENTAGE, ',','.')
                send.USER_TOKEN = 'U2FsdGVkX1+QQRAUA4cDHV3CD7OiRO1vKeeFITPDcSOglhh4FySAFzoI9in1iwvyOYHAC436mVyznYVTJRPajg=='
                const { data } = await axs.post('Quotation/QuotCalculateMotorRateV2',  new URLSearchParams(send) )

                const result = await axs.post('Quotation/QuotGetData', new URLSearchParams({
                    USER_TOKEN: 'U2FsdGVkX1+QQRAUA4cDHV3CD7OiRO1vKeeFITPDcSOglhh4FySAFzoI9in1iwvyOYHAC436mVyznYVTJRPajg==',
                    QUOT_ID: data.QUOT_ID
                }))
            
                this.resultMotor = result.data
            } catch (error) {
                console.log(error)
            }
        },
        selectBtn(type) {
            if(type === 'car') {
                this.isCar = true
            }
            if(type === 'motor') {
                this.isCar = false
            }
        },
        yearTitle(number){
            if(number == 1) return "1st Year Price"
            if(number == 2) return "2nd Year Price"
            if(number == 3) return "3rd Year Price"
            if(number == 4) return "4th Year Price"
            if(number == 5) return "5th Year Price"
            if(number == 6) return "6th Year Price"
            if(number == 7) return "7th Year Price"
            return `${number} year`
        },
        autoPercentageArr(values) {
            values[0] = this.car.VEHICLE_PRICE
            values.forEach((value, idx) => {
                if(idx > 0 && value > 999999 && this.ISSET_PERCENT) {
                    const result = (value * 100) / this.car.VEHICLE_PRICE
                    this.preCar.VEHICLE_PRICE_PERCENTAGE_ARR[idx] = result.toFixed(0)
                    this.car.VEHICLE_PRICE_PERCENTAGE_ARR = this.preCar.VEHICLE_PRICE_PERCENTAGE_ARR.join(";")
                    this.ISSET_PRICE = false
                }
                if(idx > 0 && value < 999999 && this.ISSET_PERCENT) {
                    this.preCar.VEHICLE_PRICE_PERCENTAGE_ARR[idx] = null
                    this.ISSET_PRICE = false
                }
            })
            values.length = this.car.COVERAGE_YEAR_UNTIL
            this.car.VEHICLE_PRICE_AMOUNT_ARR = values.join(";")
            this.ISSET_PERCENT = true
        },
        motorAutoPercentageArr(values) {
            values[0] = this.motor.VEHICLE_PRICE
            values.forEach((value, idx) => {
                if(idx > 0 && value > 999999 && this.M_ISSET_PERCENT) {
                    const result = (value * 100) / this.motor.VEHICLE_PRICE
                    this.preMotor.VEHICLE_PRICE_PERCENTAGE_ARR[idx] = result.toFixed(0)
                    this.motor.VEHICLE_PRICE_PERCENTAGE_ARR = this.preMotor.VEHICLE_PRICE_PERCENTAGE_ARR.join(";")
                    this.M_ISSET_PRICE = false
                }
                if(idx > 0 && value < 999999 && this.M_ISSET_PERCENT) {
                    this.preMotor.VEHICLE_PRICE_PERCENTAGE_ARR[idx] = null
                    this.M_ISSET_PRICE = false
                }
            })
            values.length = this.motor.COVERAGE_YEAR_UNTIL
            this.motor.VEHICLE_PRICE_AMOUNT_ARR = values.join(";")
            this.M_ISSET_PERCENT = true
        },
        autoAmountArr(values) {
            values[0] = "100"
            // set value vehicle price preForm
            values.forEach((value, idx) => {
                if(values[idx] > 0) {
                    values[idx] = values[idx].replace(/[^0-9]*/g,'')
                }
                if(idx > 0 && this.ISSET_PRICE) {
                    const result = (value * this.car.VEHICLE_PRICE) / 100
                    this.preCar.VEHICLE_PRICE_AMOUNT_ARR[idx] = result == 0 ? null : result
                    this.car.VEHICLE_PRICE_AMOUNT_ARR = this.preCar.VEHICLE_PRICE_AMOUNT_ARR.join(";")
                    this.ISSET_PERCENT = false
                }
            })
            values.length = this.car.COVERAGE_YEAR_UNTIL
            //set value percentage calculate
            this.car.VEHICLE_PRICE_PERCENTAGE_ARR = values.join(";")
            this.ISSET_PRICE = true
        },
        motorAutoAmountArr(values) {
            values[0] = "100"
            // set value vehicle price preForm
            values.forEach((value, idx) => {
                if(values[idx] > 0) {
                    values[idx] = values[idx].replace(/[^0-9]*/g,'')
                }
                if(idx > 0 && this.M_ISSET_PRICE) {
                    const result = (value * this.motor.VEHICLE_PRICE) / 100
                    this.preMotor.VEHICLE_PRICE_AMOUNT_ARR[idx] = result == 0 ? null : result
                    this.motor.VEHICLE_PRICE_AMOUNT_ARR = this.preMotor.VEHICLE_PRICE_AMOUNT_ARR.join(";")
                    this.M_ISSET_PERCENT = false
                }
            })
            values.length = this.motor.COVERAGE_YEAR_UNTIL
            //set value percentage calculate
            this.motor.VEHICLE_PRICE_PERCENTAGE_ARR = values.join(";")
            this.M_ISSET_PRICE = true            
        },
        changeLetters(text, from, to){
            if (!text || !from || !to) return null;
            const search = from;
            const replaceWith = to;
            return text.split(search).join(replaceWith);
        }
    },
    watch: {
        percentageArr(newValue) {
            this.autoAmountArr(newValue)
        },
        amountArr(newValue) {
            this.autoPercentageArr(newValue)
        },
        vehiclePrice() {
           this.preCar = {
                VEHICLE_PRICE_PERCENTAGE_ARR: [],
                VEHICLE_PRICE_AMOUNT_ARR: []
            }
        },
        coverageYear() {
            this.preCar = {
                VEHICLE_PRICE_PERCENTAGE_ARR: [],
                VEHICLE_PRICE_AMOUNT_ARR: []
            }
        },
        motorPercentageArr(newValue) {
            this.motorAutoAmountArr(newValue)
        },
        motorAmountArr(newValue) {
           this.motorAutoPercentageArr(newValue)
        },
        motorVechilePrice() {
           this.preMotor = {
                VEHICLE_PRICE_PERCENTAGE_ARR: [],
                VEHICLE_PRICE_AMOUNT_ARR: []
            }
        },
        motorCoverageYear() {
            this.preMotor = {
                VEHICLE_PRICE_PERCENTAGE_ARR: [],
                VEHICLE_PRICE_AMOUNT_ARR: []
            }
        },
        motorGradeRate(value) {
            if(value) {
                const selected = this.gradeCode.filter((el) => {
                    return el.LIMO_GRADE_CODE === value;
                })[0];
                this.motor.TOTAL_RATE = selected.LIMO_GRADE_RATE
            }
        }
    },
    computed: {
        percentageArr() {
            return this.preCar.VEHICLE_PRICE_PERCENTAGE_ARR
        },
        amountArr() {
            return this.preCar.VEHICLE_PRICE_AMOUNT_ARR
        },
        vehiclePrice() {
            return this.car.VEHICLE_PRICE
        },
        coverageYear() {
            return this.car.COVERAGE_YEAR_UNTIL
        },
        motorPercentageArr() {
            return this.preMotor.VEHICLE_PRICE_PERCENTAGE_ARR
        },
        motorAmountArr() {
            return this.preMotor.VEHICLE_PRICE_AMOUNT_ARR
        },
        motorVechilePrice() {
            return this.motor.VEHICLE_PRICE
        },
        motorCoverageYear() {
            return this.motor.COVERAGE_YEAR_UNTIL
        },
        motorGradeRate() {
            return this.motor.GRADE_RATE;
        }
    },
    created() {
        this.getData()
    }
}
</script>

<style scoped>

.wrapper-car {
    padding: 10px;
    margin-top:40px;
}
.car {
    display: grid;
    grid-template-columns: 1fr;
    padding: 10px;
    border-bottom: 1px dashed #ccc;
}
@media screen and (min-width: 768px) {
    .car {
         grid-template-columns: 1fr 1fr;
    }
}
input {
    padding: 7px;
    background-color: white;
}
select {
    padding: 10px;
    background-color: white;
}
</style>